import { theme } from '@/styles/theme'
import React from 'react'
import { ReactNode } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import styled from 'styled-components'
import { StepBadge } from '../elements/StepBadge'
import { Title } from '../elements/Title'

type HowtoStepProps = {
  step: number | string
  title: string | JSX.Element
  children: ReactNode
  index: number
  onClick: (index: number) => void
}

export const HowtoStep = ({
  step,
  title,
  children,
  index,
  onClick,
}: HowtoStepProps) => {
  return (
    <Wrapper>
      <StepBadge
        step={step}
        bgColor={typeof step === 'number' ? theme.colors.supayBlue : undefined}
        sp
      />
      <StepContent onClick={() => onClick(index)}>
        {children}
        <StepText>
          <Title as="h3" size={20} color={`${theme.colors.textColor}`}>
            {title}
          </Title>
          <BsChevronDown size={30} color={theme.colors.supayBlue} />
        </StepText>
      </StepContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StepContent = styled(Wrapper)`
  position: relative;
  width: 220px;
  height: 220px;
  background-color: ${theme.colors.backgroundBlue};
  border-radius: 50%;
  margin-top: -30px;
  padding-top: 25px;
  cursor: pointer;

  br {
    display: none;
  }

  img {
    height: 95px;
    object-fit: contain !important;
  }

  @media screen and (max-width: 1047px) {
    h3 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 102px;
    height: 102px;
    margin-top: -20px;
    padding-top: 15px;

    img {
      max-height: 57px;
      max-width: 82px;
    }
  }

  @media screen and (max-width: 450px) and (min-width: 376px) {
    br {
      display: flex;
    }
  }
`

const StepText = styled(Wrapper)`
  @media screen and (max-width: 500px) {
    position: absolute;
    top: 113px;
  }
`
