import styled from 'styled-components'
import React, { createRef, Fragment, RefObject, useRef } from 'react'
import { BsChevronDown, BsChevronRight } from 'react-icons/bs'
import { Link } from 'gatsby'

import { isWebviewIOS } from '@/utils/isWebview'

import { Layout } from '@/components/layouts/Layout'
import { Seo } from '@/components/Seo'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Title } from '@/components/elements/Title'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { theme } from '@/styles/theme'
import { StepBadge } from '@/components/elements/StepBadge'
import { HowtoStep } from '@/components/layouts/HowtoStep'
import { QRCode } from '@/components/elements/QRCode'
import { AppDownloadLink } from '@/components/elements/AppDownloadLink'
import { Button } from '@/components/elements/Button'
import { TermsCard } from '@/components/layouts/TermsCard'

import step1 from '../images/howto/step1.svg'
import step2 from '../images/howto/step2.svg'
import step3 from '../images/howto/step3.svg'
import step4 from '../images/howto/step4.svg'
import step1_2 from '../images/howto/step1-2.svg'
import step1_3 from '../images/howto/step1-3.svg'
import step1_4 from '../images/howto/step1-4.svg'
import step2_1_1 from '../images/howto/step2-1-1.svg'
import step2_1_2 from '../images/howto/step2-1-2.svg'
import step2_1_3 from '../images/howto/step2-1-3.svg'
import step2_1_4 from '../images/howto/step2-1-4.svg'
import step2_1_5 from '../images/howto/step2-1-5.svg'
import step2_1_6 from '../images/howto/step2-1-6.svg'
import step2_1_7 from '../images/howto/step2-1-7.svg'
import step2_1_8 from '../images/howto/step2-1-8.svg'
import step2_2_1 from '../images/howto/step2-2-1.svg'
import step2_2_2 from '../images/howto/step2-2-2.svg'
import step2_2_3 from '../images/howto/step2-2-3.svg'
import step2_3_1 from '../images/howto/step2-3-1.svg'
import step2_3_2 from '../images/howto/step2-3-2.svg'
import step2_3_3 from '../images/howto/step2-3-3.svg'
import step2_3_4 from '../images/howto/step2-3-4.svg'
import step3_1 from '../images/howto/step3-1.svg'
import step3_2 from '../images/howto/step3-2.svg'
import step4_1 from '../images/howto/step4-1.svg'
import step4_2 from '../images/howto/step4-2.svg'
import step4_3 from '../images/howto/step4-3.svg'
import step4_4 from '../images/howto/step4-4.svg'

type Step = {
  step: number | string
  img: string
  stepTitle: string | JSX.Element
  title: string
  hasSubContents: boolean
  contents: {
    item: string
    spItem?: string
    subContents?: {
      subItem: string
      img: string
    }[]
    more?: { list: string[]; button?: JSX.Element }
    img?: string | JSX.Element
  }[]
  more?: { list: string[]; button?: JSX.Element }
}

export default function howto() {
  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: '使い方',
    },
  ]

  const isIOS =
    typeof window !== 'undefined' && /[ \(]iP/.test(navigator.userAgent)

  const steps: Step[] = [
    {
      step: 1,
      img: step1,
      stepTitle: '会員登録',
      title: '会員登録',
      hasSubContents: false,
      contents: [
        {
          item: '①上記のQRコードからいますぐダウンロード！',
          spItem: '①左のボタンからいますぐダウンロード！',
          img: (
            <>
              <DownloadContainer>
                <QRCode url="https://su-pay.jp/app-download-redirect" />
                <AppDownloadLink type="iPhone" iosWidth="152px" />
                <AppDownloadLink type="Android" androidWidth="175px" />
              </DownloadContainer>
              <DownloadLinkContainer>
                <AppDownloadLink
                  type={isIOS || isWebviewIOS ? 'iPhone' : 'Android'}
                  iosWidth="133px"
                  androidWidth="133px"
                />
              </DownloadLinkContainer>
            </>
          ),
        },
        {
          item: '②「初めてアプリを使う方」をタップ',
          img: step1_2,
        },
        {
          item: '③電話番号を入力してパスコードを設定',
          img: step1_3,
        },
        {
          item: '④SMSに送られてきた認証コードを入力で完了！',
          img: step1_4,
        },
      ],
      more: {
        list: [
          'チャージやポイント利用をするにはプロフィール登録が必要です。',
          'トライアルプリペイドカードからSU-PAYに、残高とポイントを引き継ぎできます！',
        ],
        button: (
          <Button showArrowDown onClick={() => scrollToStepsClickedRef(3)}>
            引き継ぎ方法はこちら
          </Button>
        ),
      },
    },
    {
      step: 2,
      img: step2,
      stepTitle: 'チャージ',
      title: 'チャージ',
      hasSubContents: true,
      contents: [
        {
          item: 'SU-PAYで銀行チャージする場合',
          subContents: [
            {
              subItem: '①ホーム画面で「チャージする」をタップ',
              img: step2_1_1,
            },
            {
              subItem: '②「新しく銀行を登録する」をタップ',
              img: step2_1_2,
            },
            {
              subItem: '③チャージに使いたい銀行と支店を選択',
              img: step2_1_3,
            },
            {
              subItem: '④口座情報を入力',
              img: step2_1_4,
            },
            {
              subItem: '⑤金融機関の登録ページに移動し、必要な情報を入力',
              img: step2_1_5,
            },
            {
              subItem: '⑥チャージするときに使う暗証番号を登録',
              img: step2_1_6,
            },
            {
              subItem: '⑦チャージ金額を入力して「チャージする」をタップ',
              img: step2_1_7,
            },
            {
              subItem: '⑧チャージ用暗証番号を入力して「続ける」をタップ',
              img: step2_1_8,
            },
          ],
          more: {
            list: [
              '100円単位でチャージできます。',
              'チャージポイントにつきましては、今まで通り200円で1ポイント付与となります。',
              '対応する金融機関は順次拡大を予定しております。 メンテナンス時には、登録やチャージができないため、メンテナンス時間を避けてご利用ください。',
              '1回あたりのチャージ金額やご利用可能な時間帯につきましては、各金融機関によって異なりますのでご確認下さいませ。',
            ],
            button: (
              <Link to={'/faq/bankaccount/banklist'}>
                <Button showArrowRight>対応銀行一覧</Button>
              </Link>
            ),
          },
        },
        {
          item: '店舗の有人レジでチャージする場合',
          subContents: [
            {
              subItem: '①レジで「SU-PAYにチャージしたい」と伝える',
              img: step2_2_1,
            },
            {
              subItem: '②SU-PAYのバーコードを提示',
              img: step2_2_2,
            },
            {
              subItem: '③チャージ金額をお支払い 現金でチャージ可能！',
              img: step2_2_3,
            },
          ],
          more: {
            list: [
              '1000円単位でチャージできます。',
              '1回につき49,000円までチャージできます。',
              '各自治体等が発行している商品券やトライアル電子マネー以外でのチャージはできません。',
            ],
          },
        },
        {
          item: '店舗のチャージ機・セルフレジでチャージする場合',
          subContents: [
            {
              subItem: '①チャージ機でSU-PAYのバーコードをスキャン',
              img: step2_3_1,
            },
            {
              subItem: '②「チャージ」ボタンを押す',
              img: step2_3_2,
            },
            {
              subItem: '③ご希望のチャージ金額のボタンを押す',
              img: step2_3_3,
            },
            {
              subItem: '④紙幣を投入して「チャージ」ボタンを押して完了',
              img: step2_3_4,
            },
          ],
          more: {
            list: [
              '1000円単位でチャージできます。',
              '1回につき49,000円までチャージできます。',
              '各自治体等が発行している商品券やトライアル電子マネー以外でのチャージはできません。',
              'セルフレジでのバーコード読み取りはハンドスキャナで読み取りをお願いいたします。',
            ],
          },
        },
      ],
    },
    {
      step: 3,
      img: step3,
      stepTitle: 'お支払い',
      title: 'お支払い',
      hasSubContents: false,
      contents: [
        {
          item: '①レジでバーコードを読み取る',
          img: step3_1,
        },
        {
          item: '②レジの画面に沿って操作をしてお支払い完了♪',
          img: step3_2,
        },
      ],
    },
    {
      step: 'CHECK',
      img: step4,
      stepTitle: (
        <>
          残高
          <br />
          引き継ぎ
        </>
      ),
      title: 'トライアルプリペイドカードからの残高引き継ぎ',
      hasSubContents: false,
      contents: [
        {
          item: '①ホーム画面で「トライアルプリペイドカードの残高を引き継ぎの方はこちら」をタップ',
          img: step4_1,
        },
        {
          item: '②お持ちのトライアルプリペイドカードの裏面を見て、トライアルプリペイドカード番号とPINコードを確認',
          img: step4_2,
        },
        {
          item: '③トライアルプリペイドカード番号とPINコードを入力',
          img: step4_3,
        },
        {
          item: '④「残高とポイントを引き継ぐ」をタップで完了！',
          img: step4_4,
        },
      ],
      more: {
        list: [
          '引き継いだトライアルプリペイドカードは無効になりますので、ハサミを入れて処分をお願いします。',
          'お一人につき１枚限り引き継ぎができます。',
          '個人情報は引き継ぎされませんので、再度アプリでのプロフィール登録をお願いします。',
          '会員番号は新しく発行されて変更になります。',
        ],
      },
    },
  ]

  const stepsRefs = useRef<RefObject<HTMLHeadingElement>[]>([])
  steps.map((_, i) => (stepsRefs.current[i] = createRef()))

  const subStepsRefs = useRef<RefObject<HTMLHeadingElement>[]>([])
  steps[1].contents.map((_, i) => (subStepsRefs.current[i] = createRef()))

  const scrollToStepsClickedRef = (index: number) => {
    stepsRefs.current[index].current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const scrollToSubStepsClickedRef = (index: number) => {
    subStepsRefs.current[index].current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Layout>
      <Seo
        title="SU-PAYの使い方"
        canonical="https://su-pay.jp/howto/"
        description="SU-PAYの登録方法や使い方、トライアルプリペイドカードからの引き継ぎ方法をご紹介します。"
      />
      <Title as="h1" bg>
        使い方
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper
          textAlign="center"
          pcMarginBottom={62}
          spMarginBottom={50}
        >
          <Title as="h2" color={theme.colors.supayBlue}>
            SU-PAYの始め方
          </Title>
        </TitleWrapper>
        <StepContainer>
          {steps.map(({ step, img, stepTitle, title }, i) => (
            <HowtoStep
              key={step}
              step={step}
              title={stepTitle}
              index={i}
              onClick={(e) => scrollToStepsClickedRef(e)}
            >
              <img src={img} alt={title}></img>
            </HowtoStep>
          ))}
        </StepContainer>
      </MainWrapper>
      <>
        {steps.map(({ step, title, hasSubContents, contents, more }, index) => (
          <Section key={step} backgroundBlue={index % 2 === 0}>
            <SectionInner>
              <StepBadge
                step={step}
                bgColor={
                  typeof step === 'number' ? theme.colors.supayBlue : undefined
                }
              />
              <Title
                as="h2"
                color={theme.colors.textColor}
                refProp={stepsRefs.current[index]}
              >
                {title}
              </Title>
              {!hasSubContents && (
                <StepsFlow className={`step-${index + 1}`}>
                  {contents.map(({ item, spItem, img }, i, array) => (
                    <Fragment key={item}>
                      <SubStep>
                        <SpContainer>
                          {typeof img === 'string' ? (
                            <img src={img} alt={item} />
                          ) : (
                            img
                          )}
                          {i + 1 < array.length && (
                            <ArrowDown
                              size={40}
                              color={theme.colors.supayBlue}
                            />
                          )}
                        </SpContainer>
                        {spItem ? (
                          <>
                            <SubStepText>{item}</SubStepText>
                            <SpSubStepText>{spItem}</SpSubStepText>
                          </>
                        ) : (
                          <p>{item}</p>
                        )}
                      </SubStep>
                      {i + 1 < array.length && i !== 3 && (
                        <ArrowRight size={40} color={theme.colors.supayBlue} />
                      )}
                    </Fragment>
                  ))}
                </StepsFlow>
              )}
              {hasSubContents && (
                <>
                  {contents.map(({ item }, i) => (
                    <ItemsCardWrapper key={item}>
                      <TermsCard
                        title={item}
                        index={i}
                        onClick={(e) => scrollToSubStepsClickedRef(e)}
                      ></TermsCard>
                    </ItemsCardWrapper>
                  ))}
                  {contents.map(({ item, subContents, more }, i) => (
                    <Fragment key={item}>
                      <TitleWrapper
                        pcMarginTop={100}
                        pcMarginBottom={50}
                        spMarginTop={50}
                        spMarginBottom={30}
                      >
                        <Title
                          as="h3"
                          size={24}
                          color={theme.colors.supayBlue}
                          refProp={subStepsRefs.current[i]}
                        >
                          {item}
                        </Title>
                      </TitleWrapper>
                      <StepsFlow>
                        {subContents?.map(({ subItem, img }, i, array) => (
                          <Fragment key={subItem}>
                            <SubStep>
                              <SpContainer>
                                <img src={img} alt={subItem} />
                                {i + 1 < array.length && (
                                  <ArrowDown
                                    size={40}
                                    color={theme.colors.supayBlue}
                                  />
                                )}
                              </SpContainer>
                              <p>{subItem}</p>
                            </SubStep>
                            {i + 1 < array.length && i !== 3 && (
                              <ArrowRight
                                size={40}
                                color={theme.colors.supayBlue}
                              />
                            )}
                          </Fragment>
                        ))}
                      </StepsFlow>
                      {more && (
                        <More
                          bgColor={
                            index % 2 === 0
                              ? '#cedbec'
                              : theme.colors.backgroundBlue
                          }
                        >
                          <ul>
                            {more.list.map((text) => (
                              <li key={text}>{text}</li>
                            ))}
                          </ul>
                          {more.button && <div>{more.button}</div>}
                        </More>
                      )}
                    </Fragment>
                  ))}
                </>
              )}
              {more && (
                <More
                  bgColor={
                    index % 2 === 0 ? '#cedbec' : theme.colors.backgroundBlue
                  }
                >
                  <ul>
                    {more.list.map((text) => (
                      <li key={text}>{text}</li>
                    ))}
                  </ul>
                  {more.button && <div>{more.button}</div>}
                </More>
              )}
            </SectionInner>
          </Section>
        ))}
      </>
    </Layout>
  )
}

const StepContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 108px;

  @media screen and (max-width: 1047px) {
    row-gap: 28px;
    column-gap: 9px;
    margin-bottom: 55px;
  }

  @media screen and (max-width: 500px) {
    row-gap: 77px;
    padding-bottom: 78px;
  }
`

const Section = styled.section<{
  backgroundBlue?: boolean
}>`
  width: 100%;
  position: relative;
  padding: 100px 0;
  background-color: ${({ backgroundBlue }) =>
    backgroundBlue ? theme.colors.backgroundBlue : 'white'};

  &:last-child {
    padding-bottom: 0;
  }

  @media screen and (max-width: 1047px) {
    padding: 50px 0;
  }
`

const SectionInner = styled.div`
  width: ${(props) => props.theme.containerWidth};
  margin: 0 auto;

  h2 {
    line-height: 1;
    margin: 26px 0 70px;
  }

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 0 26px;

    h2 {
      font-size: 20px;
      margin: 16px 0 50px;
    }

    .step-1 div:first-child p {
      padding-top: 0px;
    }
  }
`

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const DownloadLinkContainer = styled.div`
  display: none;

  @media screen and (max-width: 1047px) {
    display: flex;
    justify-content: center;
  }
`

const StepsFlow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  row-gap: 70px;

  @media screen and (max-width: 1047px) {
    flex-direction: column;
    row-gap: 0px;
  }
`

const SubStep = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  p {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
  }

  img {
    width: 100%;
    max-width: 220px;
    object-fit: contain;
  }

  @media screen and (max-width: 1047px) {
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    gap: 0px;

    & > div {
      width: 40%;
    }

    p {
      flex: 1;
      text-align: left;
      line-height: 22px;
      padding-left: 27px;
      padding-top: 20px;
      font-size: 16px;
    }
  }
`

const SubStepText = styled.p`
  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const SpSubStepText = styled.p`
  display: none;

  @media screen and (max-width: 1047px) {
    display: flex;
  }
`

const ArrowRight = styled(BsChevronRight)`
  transform: translateY(130px);

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const ArrowDown = styled(BsChevronDown)`
  display: none;

  @media screen and (max-width: 1047px) {
    display: flex;
  }
`

const More = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 20px;
  padding: 35px 40px;
  width: 65%;
  margin: 70px auto 0;

  li + li {
    margin-top: 15px;
  }

  li {
    display: flex;
    font-size: 18px;
    line-height: 28px;
    &::before {
      content: '・';
      color: ${(props) => props.theme.colors.supayBlue};
      font-weight: bold;
      margin-right: 5px;
    }
  }

  div {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 22px;
    margin-top: 30px;

    li + li {
      margin-top: 10px;
    }

    li {
      font-size: 16px;
      line-height: 22px;
    }

    div {
      margin-top: 20px;
    }
  }
`

const SpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ItemsCardWrapper = styled.div`
  & + & {
    margin-top: 22px;
  }
`
