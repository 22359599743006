import React from 'react'
import styled from 'styled-components'
import { BsChevronDown } from 'react-icons/bs'

import { BaseCard } from '@/components/elements/BaseCard'
import { theme } from '@/styles/theme'

type TermsCardProps = {
  title: string
  index: number
  onClick: (index: number) => void
}

export const TermsCard = ({ title, index, onClick }: TermsCardProps) => {
  return (
    <BaseCard color="blue">
      <Wrapper onClick={() => onClick(index)}>
        <Contents>
          <Title>{title}</Title>
          <BsChevronDown size={30} color={theme.colors.supayBlue} />
        </Contents>
      </Wrapper>
    </BaseCard>
  )
}

const Wrapper = styled.div`
  padding: 22px;

  cursor: pointer;

  @media screen and (max-width: 1047px) {
    padding: 20px 18px;
  }
`

const Contents = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 18px;
  margin-right: 15px;
  flex: 1;

  @media screen and (max-width: 1047px) {
    font-size: 16px;
  }

  @media screen and (max-width: 350px) {
    width: 230px;
    font-size: 13px;
  }
`
