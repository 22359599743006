import React from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

type StepBadgeProps = {
  step: number | string
  bgColor?: string
  sp?: boolean
}

export const StepBadge = ({ step, bgColor, sp = false }: StepBadgeProps) => {
  const stepType = typeof step
  return (
    <Wrapper stepType={stepType} bgColor={bgColor} sp={sp}>
      {stepType === 'number' && (
        <>
          <Title sp={sp}>STEP</Title>
          <StepNumber sp={sp}>{step}</StepNumber>
        </>
      )}
      {stepType === 'string' && <Title sp={sp}>{step}</Title>}
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  stepType: string
  bgColor?: string
  sp: boolean
}>`
  width: 73px;
  height: 73px;
  background-color: ${({ bgColor }) => `${bgColor ?? 'white'}`};
  border-radius: 50%;
  color: ${({ bgColor }) => `${bgColor ? 'white' : theme.colors.supayBlue}`};
  ${({ stepType }) =>
    stepType === 'string' && `border: 2px solid ${theme.colors.supayBlue};`}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${theme.zIndex.low};
  margin: 0 auto;

  ${({ sp }) =>
    sp &&
    `@media screen and (max-width: 500px) {
  width: 46px;
  height: 46px;
}`}
`

const Title = styled.p<{ sp: boolean }>`
  font-size: 15px;
  font-weight: bold;

  ${({ sp }) => sp && `@media screen and (max-width: 500px) {font-size: 10px;}`}
`

const StepNumber = styled.p<{ sp: boolean }>`
  font-size: 29px;
  line-height: 22px;
  font-weight: bold;

  @media screen and (max-width: 1047px) {
    line-height: 29px;
  }

  @media screen and (max-width: 500px) {
    ${({ sp }) => sp && `font-size: 18px;line-height: 18px;`}
  }
`
